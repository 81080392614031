import {GlobalMonitorConfiguration} from '@/generated/monitor-api.schemas';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axios from 'axios';
import {KnownError} from './userConfig';

export const GLOBAL_MONITOR_CONFIG_URL = '/api/config/global/monitor';

export const fetchGlobalMonitorConfig = createAsyncThunk<
  GlobalMonitorConfiguration,
  string,
  {rejectValue: KnownError}
>('globalMonitorConfig/fetch', async (_, thunkAPI) => {
  return axios
    .get(`${GLOBAL_MONITOR_CONFIG_URL}`)
    .then(response => response.data)
    .catch(error => thunkAPI.rejectWithValue(error?.response || error));
});

export interface GlobalMonitorConfigState {
  loading: boolean;
  data: GlobalMonitorConfiguration | null;
  error: string | null;
}

const initialState: GlobalMonitorConfigState = {
  loading: false,
  data: null,
  error: null
};

const slice = createSlice({
  name: 'globalMonitorConfig',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchGlobalMonitorConfig.pending, state => {
        Object.assign(state, initialState);
        state.loading = true;
      })
      .addCase(fetchGlobalMonitorConfig.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchGlobalMonitorConfig.rejected, (state, action) => {
        Object.assign(state, initialState);
        state.loading = false;
        state.error = action.error as string;
        switch (action.payload?.status) {
          case 401:
          case 403:
            break;
          default:
            console.error(action.error);
        }
      });
  }
});

export default slice.reducer;
